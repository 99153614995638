<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-form @submit.prevent>
      <validation-observer ref="validationPartnerRules">
        <b-tabs
          pills
        >
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="SettingsIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Settings') }}</span>
            </template>
            <b-card>
              <div class="head-card">
                <div class="head-card-top">
                  <h4>{{ $t('Main settings') }}</h4>
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Close')"
                    icon="XIcon"
                    size="18"
                    class="toggle-close cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <hr>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('campaigns.campaigntitle')"
                    label-for="i-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LayoutIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-name"
                          v-model="partner.name"
                          :placeholder="$t('campaigns.campaigntitle')"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Api url"
                    label-for="i-api_url"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="api_url"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-api_url"
                          v-model="partner.api_url"
                          placeholder="API URL"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Native Protocol Version"
                    label-for="i-native_protocol_version"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        v
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-native_protocol_version"
                        v-model="partner.native_protocol_version"
                        placeholder="Native Protocol Version"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('partners.phx_rate')"
                    label-for="i-phx_rate"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PercentIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-phx_rate"
                        v-model="partner.phx_rate"
                        placeholder="10"
                        autocomplete="phx_rate"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('profile.notes')"
                    label-for="i-notes"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="FileTextIcon" />
                      </b-input-group-prepend>
                      <b-form-textarea
                        id="i-notes"
                        v-model="partner.notes"
                        :placeholder="$t('profile.notes')"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('sites.status')"
                    label-for="i-status"
                  >
                    <b-form-select
                      v-model="partner.status"
                      :options="statusesLocale"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                  class="d-flex align-items-center justify-content-end mt-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-0 mr-sm-1"
                    @click="$router.go(-1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('form.back') }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="partnerCreate"
                  >
                    <span class="align-middle">{{ $t('form.save') }}</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="ml-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <!--/ general tab -->
        </b-tabs>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BFormSelect,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      min,
      required,
      integer,
      partner: {
        name: '',
        status: 'inactive',
        notes: '',
        phx_rate: 0,
        api_url: '',
        native_protocol_version: '1.1',
      },
      breadcrumbItems: [],
      status: {},
      check_result: '',
      ...options,
      optionsRadio: [
        { text: 'Inactive', value: 'inactive' },
        { text: 'Active', value: 'active' },
        { text: 'Negative', value: 'negative' },
      ],
    }
  },
  computed: {
    statusesLocale() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.breadcrumbItems = [
      {
        text: 'RTB partners',
        to: '/partners/',
      },
      {
        text: 'Create',
        active: true,
      },
    ]
  },
  methods: {
    partnerCreate() {
      this.$refs.validationPartnerRules.validate().then(success => {
        if (success) {
          if (this.partner.phx_rate === '') {
            this.partner.phx_rate = 0
          }
          useJwt.createPartner(this.partner).then(result => {
            if (result) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Partner created',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'dashboard-partners' })
            }
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: err.detail,
                    },
                  })
                  const field = err.source.pointer.split('/')
                  this.$refs.validationPartnerRules.setErrors({ [field[field.length - 1]]: err.detail })
                })
              }
            }
          })
        }
      })
    },
  },
}
</script>
