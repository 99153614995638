<template>
  <b-row>
    <b-col cols="12">
      <form-new-partner />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormNewPartner from './FormNewPartner.vue'

export default {
  components: {
    BRow,
    BCol,
    FormNewPartner,
  },
}
</script>
